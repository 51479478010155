.bannerimage{
    width: 100%;
    max-height: 100vh;
    overflow: hidden;
    margin-bottom: none;
}

.abtuspgbanner{
    width: 100%;
    height: auto;  /*50vh*/
}

.abtuspgbanner img{
    width: 100%;
    height: 100vh;
}

@media (max-width: 1000px) {
    .bannerimage{
        width: 100%;
        max-height: 70vh;
        overflow: hidden;
        margin-bottom: none;
    }

    .abtuspgbanner img{
        width: 100%;
        height: 70vh;
    }
   
}

@media (max-width: 800px) {
    .bannerimage{
        width: 100%;
        max-height: 60vh;
        overflow: hidden;
        margin-bottom: none;
    }

    .abtuspgbanner img{
        width: 100%;
        height: 60vh;
    }
   
}

@media (max-width: 680px) {
    .bannerimage{
        width: 100%;
        max-height: 50vh;
        overflow: hidden;
        margin-bottom: none;
    }

    .abtuspgbanner img{
        width: 100%;
        height: 50vh;
    }
   
}

@media (max-width: 580px) {
    .bannerimage{
        width: 100%;
        max-height: 40vh;
        overflow: hidden;
        margin-bottom: none;
    }

    .abtuspgbanner img{
        width: 100%;
        height: 40vh;
    }
   
}

@media (max-width: 450px) {
    .bannerimage{
        width: 100%;
        max-height: 30vh;
        overflow: hidden;
        margin-bottom: none;
    }

    .abtuspgbanner img{
        width: 100%;
        height: 30vh;
    }
   
}
