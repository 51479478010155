.homeabtus {
    width: 100%;
    min-height: 70vh;
    background-color: #ebe992;
    overflow: hidden;
}

.homeabtusImage{
   width: 50%; 
   float: left;
   height: 70vh;
   /*border-right: 1px solid black;*/
}

.homeabtusintro{
    width: 50%; 
    float: right;
    min-height: 70vh;
}

.hmabtparasecti{
    width: 80%;
    margin-left: 0%;
    margin-top: 5vh;
}

.hmabtparasecti h2{
    margin-bottom: 3vh;
    
}

.hmabtparasecti p{
    margin-bottom: 2vh;
    font-size: 17px;
}

.hmabtimgsec{
    width: 60%;
    margin-left: 20%;
    margin-top: 5vh;
}

.hmabtimgsec img{
    width: 100%;
    height: 60vh;
}

.hnpagegallery {
    width: 100%;
    height: 90vh;
    background-color: #fff;
    overflow: hidden;
}

.testimonial {
    width: 100%;
    height: 65vh;
    background-color: #ebe992;
    overflow: hidden;
}

.sitesgrid {
    width: 100%;
    height: 100vh;
    background-color: white;
    overflow: hidden;
}

@media (max-width: 900px) {
    .hmabtimgsec{
        width: 80%;
        margin-left: 10%;
        margin-top: 5vh;
    }
}

@media (max-width: 750px) {
    .hmabtimgsec img{
        width: 100%;
        height: auto;
    }
}

@media (max-width: 600px) {
    .homeabtusImage{
        width: 80%;
        margin-left: 10%; 
        float: none;
        height: auto;
        /*border-right: 1px solid black;*/
    }

    .homeabtusintro{
        width: 80%;
        margin-left: 10%;
        float: right;
        min-height: 70vh;
    }
    
}

@media (max-width: 450px) {
    .homeabtusImage{
        overflow: hidden;
        width: 90%;
        margin-left: 5%; 
        float: none;
        height: auto;
        /*border-right: 1px solid black;*/
    }

    .homeabtusintro{
        overflow: hidden;
        width: 90%;
        margin-left: 13%;
        float: none;
        min-height: 10vh;
    }
    
}