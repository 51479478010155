.gallerypage{
    width: 100%;
    height: auto;
    overflow: hidden;
    padding-bottom: 5vh;
}

.gallerytitle{
    width: 100%;
    margin-top: 8vh;
}

.gallerytitle h1{
    width: 100%;
    text-align: center;
    margin-bottom: 2vh;
}

.ttleunderline{
    width: 5%;
    border-bottom: 2px solid black;
    margin-left: 47%;
    margin-bottom: 5vh;
}

.galleryimagesect{
    width: 80%;
    margin-left: 10%;
}

.galleryimage{
    width: 23%;
    height: 40vh;
    float: left;
    margin-left: 1.5%;
    margin-bottom: 3vh;
}

.galleryimage img{
    width: 100%;
    height: 100%;
}

@media (max-width: 800px) {

    .galleryimage{
        width: 23%;
        height: 25vh;
        float: left;
        margin-left: 1.5%;
        margin-bottom: 3vh;
    }

}

@media (max-width: 550px) {

    .galleryimage{
        width: 44%;
        height: 25vh;
        float: left;
        margin-left: 4%;
        margin-bottom: 3vh;
    }

}

@media (max-width: 400px) {

    .galleryimage{
        width: 44%;
        height: 20vh;
        float: left;
        margin-left: 4%;
        margin-bottom: 3vh;
    }

}

