.homevideogrid{
    width: 100%;
    height: auto;
    background-color: #ebe992;
    overflow: hidden;
    padding-bottom: 7vh;
}

.videocont{
    width: 60%;
    height: 60vh;
    margin-left: 20%;
    margin-top: 2vh;
    background-color: white;
}

.videocont iframe {
    width: 100%;
    height: 60vh;;
    border: 1px solid black;
    
}

@media (max-width: 900px) {

    .videocont{
        width: 80%;
        height: 60vh;
        margin-left: 10%;
        margin-top: 2vh;
        background-color: white;
    }

    
}

@media (max-width: 650px) {

    .videocont{
        width: 90%;
        height: 50vh;
        margin-left: 5%;
        margin-top: 2vh;
        background-color: white;
    }

    .videocont iframe {
        width: 100%;
        height: 50vh;;
        border: 1px solid black;
        
    }

    
}

@media (max-width: 500px) {

    .videocont{
        width: 90%;
        height: 40vh;
        margin-left: 5%;
        margin-top: 2vh;
        background-color: white;
    }

    .videocont iframe {
        width: 100%;
        height: 40vh;;
        border: 1px solid black;
        
    }

    
}


@media (max-width: 400px) {

    .videocont{
        width: 90%;
        height: 30vh;
        margin-left: 5%;
        margin-top: 2vh;
        background-color: white;
    }

    .videocont iframe {
        width: 100%;
        height: 30vh;;
        border: 1px solid black;
        
    }

    
}