.navbar {
  width: 100%;
  background-color: #ebe992;
  overflow: hidden;
}

.mainlinksimg {
  width: 7%;
  margin-top: 1vh;
  margin-left: 1%;
  float: left;
  margin-right: 20%;
}

.mainlinksimg img{
  width: 100%;
}

.mainlinks {
  
  width: 60%;
  float: right;
  margin-top: 6vh;
  /*background-color: blueviolet;*/
  
}

.mainlinks a {
  text-decoration: none;
  color: black;
  font-size: 1rem;
  padding-left: 3%;
}

.close-iconav{
  display: none;
}

.menu-iconav{
  display: none;
}


@media screen and (max-width: 1000px) {

  .mainlinksimg {
    width: 9%;
    margin-top: 1vh;
    margin-left: 1%;
    float: left;
    margin-right: 20%;
  }


  .mainlinks {
    width: 60%;
    float: left;
    margin-top: 6vh; 
  }

}

@media screen and (max-width: 800px) {

  .mainlinksimg {
    width: 10%;
    margin-top: 1vh;
    margin-left: 1%;
    float: left;
    margin-right: 10%;
  }


  .mainlinks {
    width: 70%;
    float: left;
    margin-top: 5vh;
  }

}


@media screen and (max-width: 670px) {

  .mainlinksimg {
    width: 80px;
    margin-top: 1vh;
    margin-left: 1%;
    float: left;
    margin-right: 20%;
  }
  

  
  .mainlinks {
      display: flex;
      flex-direction: column;
      position: fixed;
      top: 0;
      left: 0;
      margin-top: 0vh;
      width: 0%;
      overflow: hidden;
      background-color: white;
      transition: 0.5s;
  }

  .mainlinks a {
    text-decoration: none;
    color: white;
    font-size: 1.1rem;
    padding-left: 3%;
    padding-bottom: 2vh;
    transition: 0.3s;
  }
  
  
  
  .menu-iconav {
      display: block;
      overflow: hidden;
      position: fixed;
      font-size: 1.5rem;
      width: 28px;
      left: 90%;
      top: 30px;
      background-color: white;
      padding-left: 0.6%;
      z-index: 5;
    
  }
  
  .close-iconav {
      display: none;
      
  }

  
  .show-menunav {
      /*position: fixed;*/
      width: 70%;
      z-index: 1;
      background-color: black;
    

  }
  
  .show-iconav {
      display: block;
      position: relative;
      background-color: white;
      font-size: 2.3rem;
      left: 90%;
      top: 10px;
      z-index: 1;
      width: 7%;
      padding-left: 0.8%;
  }
  
  .hide-iconav {
      display: none;
  }

  
}


@media screen and (max-width: 500px) {

  .mainlinksimg {
    width: 70px;
    margin-top: 1vh;
    margin-left: 1%;
    float: left;
    margin-right: 20%;
  }

  
  .mainlinks {
      display: flex;
      flex-direction: column;
      position: fixed;
      top: 0;
      left: 0;
      margin-top: 0vh;
      width: 0%;
      overflow: hidden;
      background-color: white;
      transition: 0.5s;
  }

  .mainlinks a {
    text-decoration: none;
    color: white;
    font-size: 1.1rem;
    padding-left: 3%;
    padding-bottom: 2vh;
    transition: 0.3s;
  }
  
  
  
  .menu-iconav {
      display: block;
      overflow: hidden;
      position: fixed;
      font-size: 1.5rem;
      width: 28px;
      left: 90%;
      top: 25px;
      background-color: white;
      padding-left: 0.6%;
      z-index: 5;
    
  }
  
  .close-iconav {
      display: none;
      
  }

  
  .show-menunav {
      /*position: fixed;*/
      width: 100%;
      z-index: 1;
      background-color: black;
    

  }
  
  .show-iconav {
      display: block;
      position: relative;
      background-color: white;
      font-size: 2.3rem;
      left: 90%;
      top: 10px;
      z-index: 1;
      width: 8%;
      padding-left: 1%;
  }
  
  .hide-iconav {
      display: none;
  }

  
}
/*.dropdown {
  float: left;
}


.dropdown-content {
  display: none;
}





.App {
  text-align: center;
}

.navbar {
  background-color: #333;
  overflow: hidden;
}

.logo {
  height: 50px;
  margin: 10px;
}

.navbar a {
  float: left;
  font-size: 18px;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.navbar a:hover {
  background-color: black;
  color: #ddd;
}

.dropdown-container {
  display: inline-block;
  position: relative;
  background-color: #333;
}

.dropdown {
  display: none;
  position: absolute;
  background-color: #333;
  min-width: 160px;
  z-index: 1;
}

.dropdown-content {
  display: flex;
  flex-direction: column;
}

.dropdown a {
  float: none;
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown a:hover {
  background-color: #ddd;
  color: black;
}

.dropdown-container:hover .dropdown {
  display: block;
}*/
