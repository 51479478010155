.footer {
    width: 100%;
    min-height: 70vh;
    background-color: black;
}

.footerlogo {
    width: 100%;
    height: auto;
    overflow: hidden;
    
}

.silwanelogo{
    width: 100px;
    float: left;
    margin-left: 45%;
    margin-top: 1vh;
}

.reservelogo{
    width: 70%;
    float: left;
    margin-left: 1%;
    margin-top: 5vh;
}

.footerdetails{
    width: 100%;
    padding-top: 4vh;
    padding-bottom: 4vh;
    height: auto;
    overflow: hidden;
   
}

.ftabtus {
    position: relative;
    width: 18%;
    margin-left: 5%;
    float: left;
    color: white;
    
}

.ftabtus h3{
    padding-bottom: 3vh;
    
}

.ftabtus p{
    margin-top: 2vh;
    font-size: 15px;
}

.footerlinks {
    width: 18%;
    margin-left: 3%;
    float: left;
    color: white;
}

.footerlinks h3{
    padding-bottom: 5vh;
    
}

.footerlinks p{
    margin-top: 1.2vh;
}

.footerlinks a{
    text-decoration: none;
    color: white;
}

.enquire{
    width: 18%;
    margin-left: 3%;
    float: left;
    color: white;

}

.enquire h3{
    padding-bottom: 5vh;

}

.enquire input{
    font-size: 14px;
    height: 4vh;
    width: 80%;
    margin-bottom: 2vh;
}

.enMessage{
    font-size: 14px;
    height: 10vh;
    min-width: 79.9%;
    max-width: 80%;
    margin-bottom: 2vh;

}

.enquire button{
    width: 25%;
    font-size: 15px;
    border: 1px solid white;
    height: 5vh;
    background-color: black;
    color: white;
}

.enquire button:hover{
    background-color: white;
    color: black;
    cursor: pointer;
}

.officehrs {
    width: 18%;
    margin-left: 3%;
    float: left;
    color: white;
}

.officehrs h3{
    padding-bottom: 5vh;
}

@media (max-width: 940px) {
    .ftabtus {
        width: 29.333%;
        margin-left: 3%;
        float: left;
        color: white;
        
    }

    .footerlinks {
        width: 20.333%;
        margin-left: 12%;
        float: left;
        color: white;
    }

    .enquire{
        width: 29.333%;
        margin-left: 3%;
        float: left;
        color: white;
    
    }

    .officehrs {
        width: 97%;
        margin-left: 3%;
        margin-top: 2vh;
        float: left;
        color: white;
    }

    .reservelogo{
        width: 40%;
        float: left;
        margin-left: 1%;
        margin-top: 5vh;
    }
    
}

@media (max-width: 680px) {
    .silwanelogo{
        width: 100px;
        float: left;
        margin-left: 40%;
        margin-top: 1vh;
    }

    .ftabtus {
        width: 44%;
        margin-left: 4%;
        float: left;
        color: white;
        overflow: hidden;
        
    }

    .footerlinks {
        width: 44%;
        margin-left: 4%;
        float: left;
        color: white;
        overflow: hidden;
    }

    .enquire{
        width: 44%;
        margin-right: 4%;
        float: right;
        color: white;
        overflow: hidden;
        margin-top: 2vh;
    
    }

    .officehrs {
        width: 44%;
        margin-left: 4%;
        margin-top: 2vh;
        float: left;
        color: white;
        overflow: hidden;
        
    }

    .reservelogo{
        width: 40%;
        float: left;
        margin-left: 1%;
        margin-top: 5vh;
    }
    
}

@media (max-width: 450px) {
    .silwanelogo{
        width: 100px;
        float: left;
        margin-left: 35%;
        margin-top: 1vh;
    }

    .ftabtus {
        width: 80%;
        margin-left: 10%;
        float: left;
        color: white;
        overflow: hidden;
        
    }

    .footerlinks {
        width: 80%;
        margin-left: 10%;
        float: left;
        color: white;
        overflow: hidden;
        margin-top: 4vh;
    }

    .enquire{
        width: 80%;
        margin-right: 10%;
        float: right;
        color: white;
        overflow: hidden;
        margin-top: 4vh;
    
    }

    .officehrs {
        width: 80%;
        margin-left: 10%;
        margin-top: 4vh;
        float: left;
        color: white;
        overflow: hidden;
        
    }

    .reservelogo{
        width: 40%;
        float: left;
        margin-left: 1%;
        margin-top: 5vh;
    }
    
}



