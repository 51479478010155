.bookingpage{
    width: 100%;
    height: auto;
}

/*.bookingpgbanner{
    width: 100%;
    height: 50vh;
}

.bookingpgbanner img{
    width: 100%;
    height: 50vh;
}*/

.bookingqoute{
    width: 100%;
    min-height: 18vh;
    background-color: #ebe992;
    color: black;
    padding-bottom: 2vh;
}

.bkqoutesect{
    width: 60%;
    margin-left: 20%;
    padding-top: 5vh;
}

.bkqoutesect p{
    text-align: center;
    font-size: 17px;
    margin-bottom: 2vh;
}

.reservationsect {
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
}

.reservationsect h2{
    width: 100%;
    text-align: center;
    padding-top: 4vh;
    padding-bottom: 3vh;
}



.reservationimg{
    width: 50%;
    float: right;
}

.reservationimg img{
    width:70%;
    height: 70vh;
    float: left;
}

.reservationdetails{
    width: 50%;
    float: left;
}

.reservationinfo{
    width: 70%;
    margin-left: 15%;
}

.reservunderline{
    width: 20%;
    margin-left: 40%;
    border-bottom: 3px solid black;
    margin-bottom: 3vh;
}

.reservationinfo p{
    width: 100%;
    text-align: center;
    margin-bottom: 3vh;
}

.reverstable{
    width: 76%;
    margin-left: 11%;
    height: 15vh;
    border: 1px solid black;
    overflow: hidden;
    margin-bottom: 5vh;
}

.adltsect{
    width: 50%;
    float: left;
    height: 5vh;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
}

.adltsect p{
    padding-top: 1vh;
    width: 100%;
    text-align: left;
    padding-left: 10%;
    font-size: 14px;

}

.adltprice{
    width: 50%;
    float: right;
    height: 5vh;
    border-bottom: 1px solid black;
}

.adltprice p{
    padding-top: 1vh;
    width: 100%;
    text-align: left;
    padding-left: 10%;
    font-size: 14px;

}

.childsect{
    width: 50%;
    float: left;
    height: 5vh;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
}

.childsect p{
    padding-top: 1vh;
    width: 100%;
    text-align: left;
    padding-left: 10%;
    font-size: 14px;

}

.childprice{
    width: 50%;
    float: right;
    height: 5vh;
    border-bottom: 1px solid black;
}

.childprice p{
    padding-top: 1vh;
    width: 100%;
    text-align: left;
    padding-left: 10%;
    font-size: 14px;

}

.carsect{
    width: 50%;
    float: left;
    height: 5vh;
    border-right: 1px solid black;
}

.carsect p{
    padding-top: 1vh;
    width: 100%;
    text-align: left;
    padding-left: 10%;
    font-size: 14px;

}

.carprice{
    width: 50%;
    float: right;
    height: 5vh;
}

.carprice p{
    padding-top: 1vh;
    width: 100%;
    text-align: left;
    padding-left: 10%;
    font-size: 14px;

}

.booknow{
    margin-left: 40%;
    height: 5vh;
    width: 20%;
    font-size: 15px;
    background-color: white;
}

.booknow:hover{
    background-color: #ebe992;
    color: black;
}

@media (max-width: 950px) {
    .reservationimg img{
        width:80%;
        height: 60vh;
        float: left;
    }


}

@media (max-width: 660px) {
    .reservationdetails{
        width: 100%;
        float: none;
        overflow: hidden;
        padding-bottom: 5vh;
    }

    .reservationimg{
        width: 100%;
        float: none;
        overflow: hidden;
        padding-bottom: 3vh;
    }
    
    .reservationimg img{
        width:70%;
        height: 50vh;
        float: none;
        margin-left: 15%;
    }
    


}


