.contactuspage{
    width: 100%;
    height: auto;
    overflow: hidden;
}

.contactusmap{
    width: 50%;
    height: 100vh;
    float: left;
}

.contactarea{
    width: 80%;
    height: 80vh;
    margin-left: 10%;
    margin-top: 10vh;
}

.contactarea iframe{
    width: 100%;
    height: 100%;
}
.contactusdetails{
    width: 50%;
    height: 100vh;
    float: right;
}

.contthedetails {
    width: 80%;
    margin-top: 10vh;
}

.contthedetails h2{
    margin-bottom: 3vh;
}

.contusunderline{
    width: 10%;
    border: 1px solid black;
    margin-bottom: 3vh;
}

.cpageformdetails {
    width: 100%;
    overflow: hidden;
}
.cpageformdetails p{
    margin-bottom: 2vh;
}

.cpageformdetails .emailnum{
    width: 100%;
    float: none;
}

.cpageformdetails .phoneenum{
    width: 100%;
    float: none;
}



.Nameres{
    width: 45%;
    height: 5vh;
    font-size: 17px;
    padding-left: 2%;
    margin-right: 5%;
    margin-bottom: 3vh;
}


.Surnameres{
    width: 45%;
    height: 5vh;
    font-size: 17px;
    padding-left: 2%;
    margin-bottom: 3vh;

}

.Telres{
    width: 45%;
    height: 5vh;
    font-size: 17px;
    padding-left: 2%;
    margin-right: 5%;
    margin-bottom: 3vh;
}
.Emailres{
    width: 45%;
    height: 5vh;
    font-size: 17px;
    padding-left: 2%;
    margin-bottom: 3vh;

}

.Messageres{
    width: 95%;
    height: 5vh;
    font-size: 17px;
    padding-left: 2%;
    margin-bottom: 3vh;
    padding-top: 1vh;
    min-height: 20vh;
    max-height: 20vh;
    min-width: 95%;
}

/*.subcontactinfo{
    width: 20%;
    height: 5vh;
    font-size: 16px;
    background-color: white;

}

.subcontactinfo:hover{
    background-color: #ebe992;
}*/

.subcontactinfo{
    width: 25%;
    font-size: 15px;
    border: 1px solid white;
    height: 5vh;
    background-color: black;
    color: white;
    border-radius: 10px;
}

.subcontactinfo:hover{
    background-color: black;
    color: #ebe992;
    cursor: pointer;
}

@media (max-width: 1029px) {

    .cpageformdetails .emailnum{
        width: 100%;
        float: none;
       
    }
    
    .cpageformdetails .phoneenum{
        width: 100%;
        float: none;
        margin-left: 0%;
    }


}

@media (max-width: 800px) {

    .contactusmap{
        width: 100%;
        height: auto;
        float: none;
        overflow: hidden;
        padding-bottom: 2vh;
    }

    .contactarea{
        width: 80%;
        height: 50vh;
        margin-left: 10%;
        margin-top: 10vh;
    }

    .contactusdetails{
        width: 100%;
        height: auto;
        float: none;
        margin-bottom: 5vh;
        overflow: hidden;
        
    }

    .contthedetails {
        width: 80%;
        margin-top: 10vh;
        margin-left: 10%;
    }
    
    


}

@media (max-width: 500px) {
    .Nameres{
        width: 95%;
        height: 5vh;
        font-size: 17px;
        padding-left: 2%;
        margin-right: 5%;
        margin-bottom: 3vh;
    }
    
    
    .Surnameres{
        width: 95%;
        height: 5vh;
        font-size: 17px;
        padding-left: 2%;
        margin-bottom: 3vh;
    
    }
    
    .Telres{
        width: 95%;
        height: 5vh;
        font-size: 17px;
        padding-left: 2%;
        margin-right: 5%;
        margin-bottom: 3vh;
    }
    .Emailres{
        width: 95%;
        height: 5vh;
        font-size: 17px;
        padding-left: 2%;
        margin-bottom: 3vh;
    
    }

}




