.imagegridsect {
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color: blueviolet;
    height: auto;
}

.image1 {
    width: 50%;
    height: 50vh;
    float: left;
    background-color: blanchedalmond;
}

.image1 img {
    width: 100%;
    height: 50vh;
}

.image2 {
    width: 50%;
    height: 50vh;
    float: right;
    background-color: blue;
}

.image2 img {
    width: 100%;
    height: 50vh;
}

.image3 {
    width: 50%;
    height: 50vh;
    float: left;
    background-color: aqua;
}

.image3 img {
    width: 100%;
    height: 50vh;
}

.image4 {
    width: 50%;
    height: 50vh;
    float: right;
    background-color: blueviolet;
}

.image4 img {
    width: 100%;
    height: 50vh;
}

.labelitem {
    position: relative;
    bottom: 25vh;
    
}

.labelitem p{
    
    font-size: 20px;
    
    text-align: center;
    color: white;
    padding-top: 1vh;
}

.labelitem:hover{
    background-color: grey;
}

@media (max-width: 550px) {

  

    .image1 {
        width: 50%;
        height: 40vh;
        float: left;
        background-color: blanchedalmond;
    }
    
    .image1 img {
        width: 100%;
        height: 40vh;
    }
    
    .image2 {
        width: 50%;
        height: 40vh;
        float: right;
        background-color: blue;
    }
    
    .image2 img {
        width: 100%;
        height: 40vh;
    }
    
    .image3 {
        width: 50%;
        height: 40vh;
        float: left;
        background-color: aqua;
    }
    
    .image3 img {
        width: 100%;
        height: 40vh;
    }
    
    .image4 {
        width: 50%;
        height: 40vh;
        float: right;
        background-color: blueviolet;
    }
    
    .image4 img {
        width: 100%;
        height: 40vh;
    }

    .labelitem {
        position: relative;
        bottom: 25vh;
        
    }
}

@media (max-width: 450px) {
    .image1 {
        width: 50%;
        height: 30vh;
        float: left;
        background-color: blanchedalmond;
    }
    
    .image1 img {
        width: 100%;
        height: 30vh;
    }
    
    .image2 {
        width: 50%;
        height: 30vh;
        float: right;
        background-color: blue;
    }
    
    .image2 img {
        width: 100%;
        height: 30vh;
    }
    
    .image3 {
        width: 50%;
        height: 30vh;
        float: left;
        background-color: aqua;
    }
    
    .image3 img {
        width: 100%;
        height: 30vh;
    }
    
    .image4 {
        width: 50%;
        height: 30vh;
        float: right;
        background-color: blueviolet;
    }
    
    .image4 img {
        width: 100%;
        height: 30vh;
    }

    .labelitem {
        position: relative;
        bottom: 15vh;
        
    }
}
