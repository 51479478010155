.testimonialcarousel{
    width: 100%;
    min-height: 40vh;
    overflow: hidden;
    background-color: #ebe992;
    padding-top: 5vh;
    padding-bottom: 5vh;
}

.testismonialsentence{
    width: 60%;
    margin-left: 20%;
    margin-top: 3vh;
}

.testinonialtspara{
    font-size: 25px;
    text-align: center;

}

.testimonialprev{
    position: absolute;
    font-size: 1em;
    padding: 10px 20px;
    left: 30px;
    margin-top: 10vh;
    background-color: #ebe992;
    border: 3px solid white;
    color: white;
    cursor: pointer;

}

.testimonialnext{
    position: absolute;
    font-size: 1em;
    padding: 10px 20px;
    right: 30px;
    margin-top: 10vh;
    background-color: #ebe992;
    border: 3px solid white;
    color: white;
    cursor: pointer;
    

}

@media (max-width: 909px) {

    .testimonialprev{
        position: absolute;
        font-size: 1em;
        padding: 10px 20px;
        left: 30px;
        margin-top: 15vh;
        background-color: #ebe992;
        border: 3px solid white;
        color: white;
        cursor: pointer;
    
    }
    
    .testimonialnext{
        position: absolute;
        font-size: 1em;
        padding: 10px 20px;
        right: 30px;
        margin-top: 15vh;
        background-color: #ebe992;
        border: 3px solid white;
        color: white;
        cursor: pointer;
        
    
    }

}

@media (max-width: 652px) {

    .testinonialtspara{
        font-size: 20px;
        text-align: center;
    
    }

    .testimonialprev{
        position: absolute;
        font-size: 1em;
        padding: 10px 20px;
        left: 30px;
        margin-top: 15vh;
        background-color: #ebe992;
        border: 3px solid white;
        color: white;
        cursor: pointer;
    
    }
    
    .testimonialnext{
        position: absolute;
        font-size: 1em;
        padding: 10px 20px;
        right: 30px;
        margin-top: 15vh;
        background-color: #ebe992;
        border: 3px solid white;
        color: white;
        cursor: pointer;
        
    
    }

}


@media (max-width: 460px) {

    .testinonialtspara{
        font-size: 18px;
        text-align: center;
    
    }

    .testimonialprev{
        position: absolute;
        font-size: 0.5em;
        padding: 10px 20px;
        left: 10px;
        margin-top: 15vh;
        background-color: #ebe992;
        border: 3px solid white;
        color: white;
        cursor: pointer;
    
    }
    
    .testimonialnext{
        position: absolute;
        font-size: 0.5em;
        padding: 10px 20px;
        right: 10px;
        margin-top: 15vh;
        background-color: #ebe992;
        border: 3px solid white;
        color: white;
        cursor: pointer;
        
    
    }

}