.carousel {
    position: relative;
    width: 100%;
    margin-top: 0%;
    height: 65vh;
    margin-left: 0%;
    overflow: hidden;
}
  
  .slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: opacity 1s ease-in-out;
  }
  
  .slide.active {
    opacity: 1;
  }
  
  .slide:nth-child(1) {
    transform: translateX(0%);
  }
  
  .slide:nth-child(2) {
    transform: translateX(100%);
  }
  
  .slide:nth-child(3) {
    transform: translateX(200%);
  }
  
  .carousel .slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .prev,
  .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    font-weight: bold;
    border: none;
    background-color: transparent;
    color: white;
    cursor: pointer;
  }
  
  .prev {
    left: 10px;
    font-size: 35px;
  }
  
  .next {
    right: 10px;
    font-size: 35px;
  }


  .caranumberid1 {
    position: absolute;
    top: 45%;
    left: 0%;
    font-size: 35px;
    border: none;
    background-color: transparent;
    color: #fff;
    cursor: pointer;
    width: 100%;
    text-align: center;
    /*-webkit-animation: fly 2s linear 0s;
    -moz-animation: fly 2s linear 0s;
    animation: fly 2s linear 0s;*/
    animation-duration: 3s;
    animation-name: slidein;
    /*animation-iteration-count: infinite;
    animation-delay: 2s;*/

  }

  .caranumberid2 {
    position: absolute;
    top: 45%;
    left: 0%;
    font-size: 35px;
    border: none;
    background-color: transparent;
    color: #fff;
    cursor: pointer;
    width: 100%;
    text-align: center;
  }

  @-webkit-keyframes slidein {
    from {
        top: 100%;
        left: 0%;
    }

    to {
        top: 45%;
        left: 0%;
    }

  }

  @keyframes slidein {
    from{
        top: 100%;
        left: 0%;
    }

    to{
        top: 45%;
        left: 0%;
    }


  }

  

  
  @media (max-width: 768px) {
    .carousel {
      height: 200px;
    }
  }

  @media (max-width: 670px) {
    .caranumberid1 {
      position: absolute;
      top: 40%;
      left: 0%;
      font-size: 30px;
      border: none;
      background-color: transparent;
      color: #fff;
      cursor: pointer;
      width: 100%;
      text-align: center;
      /*-webkit-animation: fly 2s linear 0s;
      -moz-animation: fly 2s linear 0s;
      animation: fly 2s linear 0s;*/
      animation-duration: 3s;
      animation-name: slidein;
      /*animation-iteration-count: infinite;
      animation-delay: 2s;*/
  
    }
  
    .caranumberid2 {
      position: absolute;
      top: 40%;
      left: 0%;
      font-size: 30px;
      border: none;
      background-color: transparent;
      color: #fff;
      cursor: pointer;
      width: 100%;
      text-align: center;
    }

    @-webkit-keyframes slidein {
      from {
          top: 100%;
          left: 0%;
      }
  
      to {
          top: 40%;
          left: 0%;
      }
  
    }
  
    @keyframes slidein {
      from{
          top: 100%;
          left: 0%;
      }
  
      to{
          top: 40%;
          left: 0%;
      }
  
  
    }
  }

  @media (max-width: 580px) {
    .caranumberid1 {
      position: absolute;
      top: 40%;
      left: 0%;
      font-size: 25px;
      border: none;
      background-color: transparent;
      color: #fff;
      cursor: pointer;
      width: 100%;
      text-align: center;
      /*-webkit-animation: fly 2s linear 0s;
      -moz-animation: fly 2s linear 0s;
      animation: fly 2s linear 0s;*/
      animation-duration: 3s;
      animation-name: slidein;
      /*animation-iteration-count: infinite;
      animation-delay: 2s;*/
  
    }
  
    .caranumberid2 {
      position: absolute;
      top: 40%;
      left: 0%;
      font-size: 25px;
      border: none;
      background-color: transparent;
      color: #fff;
      cursor: pointer;
      width: 100%;
      text-align: center;
    }

    @-webkit-keyframes slidein {
      from {
          top: 100%;
          left: 0%;
      }
  
      to {
          top: 40%;
          left: 0%;
      }
  
    }
  
    @keyframes slidein {
      from{
          top: 100%;
          left: 0%;
      }
  
      to{
          top: 40%;
          left: 0%;
      }
  
  
    }
  }

  @media (max-width: 500px) {
    .caranumberid1 {
      position: absolute;
      top: 40%;
      left: 0%;
      font-size: 25px;
      border: none;
      background-color: transparent;
      color: #fff;
      cursor: pointer;
      width: 80%;
      margin-left: 10%;
      text-align: center;
      /*-webkit-animation: fly 2s linear 0s;
      -moz-animation: fly 2s linear 0s;
      animation: fly 2s linear 0s;*/
      animation-duration: 3s;
      animation-name: slidein;
      /*animation-iteration-count: infinite;
      animation-delay: 2s;*/
  
    }
  
    .caranumberid2 {
      position: absolute;
      top: 40%;
      left: 0%;
      font-size: 25px;
      border: none;
      background-color: transparent;
      color: #fff;
      cursor: pointer;
      width: 80%;
      margin-left: 10%;
      text-align: center;
    }

    @-webkit-keyframes slidein {
      from {
          top: 100%;
          left: 0%;
      }
  
      to {
          top: 40%;
          left: 0%;
      }
  
    }
  
    @keyframes slidein {
      from{
          top: 100%;
          left: 0%;
      }
  
      to{
          top: 40%;
          left: 0%;
      }
  
  
    }
  }
  
    