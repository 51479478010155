.productcarousel{
    width: 100%;
    overflow: hidden;
    background-color: #ebe992;
    min-height: 20vh;
}

.productimgblock{
    width: 80%;
    margin-left: 10%;
    height: 70vh;
   
}

.actualproductimg{
    width: 30%;
    height: 70vh;
    margin-left: 2.5%;
    float: left;
    
}

.actualproductimg img{
    width: 100%;
    height: 100%;
    
}

.prodprevbutton{
    position: absolute;
    font-size: 1em;
    padding: 10px 20px;
    left: 30px;
    margin-top: 30vh;
    background-color: transparent;
    border: 3px solid white;
    color: white;
    cursor: pointer;
   
   
   
}

.prodnextbutton{
    position: absolute;
    font-size: 1em;
    padding: 10px 20px;
    right: 30px;
    margin-top: 30vh;
    background-color: transparent;
    border: 3px solid white;
    color: white;
    cursor: pointer;
    
   
   
}


@media (max-width: 1000px) {
    .productimgblock{
        width: 80%;
        margin-left: 10%;
        height: 55vh;
    }
    
    .actualproductimg{
        width: 30%;
        height: 55vh;
        margin-left: 2.5%;
        float: left;
    }

    .prodprevbutton{
        position: absolute;
        font-size: 1em;
        padding: 10px 20px;
        left: 30px;
        margin-top: 25vh;
        background-color: transparent;
        border: 3px solid white;
        color: white;
        cursor: pointer;
       
       
    }
    
    .prodnextbutton{
        position: absolute;
        font-size: 1em;
        padding: 10px 20px;
        right: 30px;
        margin-top: 25vh;
        background-color: transparent;
        border: 3px solid white;
        color: white;
        cursor: pointer;
       
       
    }
    
}

@media (max-width: 800px) {
    .productimgblock{
        width: 80%;
        margin-left: 10%;
        height: 45vh;
    }
    
    .actualproductimg{
        width: 30%;
        height: 45vh;
        margin-left: 2.5%;
        float: left;
    }

    .prodprevbutton{
        position: absolute;
        font-size: 1em;
        padding: 10px 20px;
        left: 15px;
        margin-top: 20vh;
        background-color: transparent;
        border: 3px solid white;
        color: white;
        cursor: pointer;
       
       
    }
    
    .prodnextbutton{
        position: absolute;
        font-size: 1em;
        padding: 10px 20px;
        right: 15px;
        margin-top: 20vh;
        background-color: transparent;
        border: 3px solid white;
        color: white;
        cursor: pointer;
       
       
    }
    
}


@media (max-width: 700px) {
    .productimgblock{
        width: 80%;
        margin-left: 10%;
        height: 35vh;
    }
    
    .actualproductimg{
        width: 30%;
        height: 35vh;
        margin-left: 2.5%;
        float: left;
    }

    .prodprevbutton{
        position: absolute;
        font-size: 1em;
        padding: 10px 20px;
        left: 10px;
        margin-top: 15vh;
        background-color: transparent;
        border: 3px solid white;
        color: white;
        cursor: pointer;
       
       
    }
    
    .prodnextbutton{
        position: absolute;
        font-size: 1em;
        padding: 10px 20px;
        right: 10px;
        margin-top: 15vh;
        background-color: transparent;
        border: 3px solid white;
        color: white;
        cursor: pointer;
       
       
    }
    
}

@media (max-width: 600px) {
    .productimgblock{
        width: 100%;
        margin-left: 0%;
        height: 35vh;
    }
    
    .actualproductimg{
        width: 33.3333%;
        height: 35vh;
        margin-left: 0%;
        float: left;
    }

    .prodprevbutton{
        position: absolute;
        font-size: 1em;
        padding: 10px 20px;
        left: 10px;
        margin-top: 15vh;
        background-color: transparent;
        border: 3px solid white;
        color: white;
        cursor: pointer;
       
       
    }
    
    .prodnextbutton{
        position: absolute;
        font-size: 1em;
        padding: 10px 20px;
        right: 10px;
        margin-top: 15vh;
        background-color: transparent;
        border: 3px solid white;
        color: white;
        cursor: pointer;
       
 
    }
    
}

















/*.product-carousel {
    position: relative;
    width: 20%;
    height: 100vh;
  }
  
  .carousel-container {
    display: flex;
    overflow: hidden;
  }
  
  .slide {
    flex: 0 0 auto;
    transition: all 0.5s ease;
  }
  
  .slide.active {
    opacity: 1;
  }
  
  img {
    width: 100%;
    height: auto;
  }
  
  button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  .prev {
    left: 10px;
  }
  
  .next {
    right: 10px;
  }*/
  