.experiencespage{
    width: 100%;
    height: auto;
}

/*.experiencepgbanner{
    width: 100%;
    height: 50vh;
}

.experiencepgbanner img{
    width: 100%;
    height: 50vh;
}*/

.experienceqoute{
    width: 100%;
    min-height: 20vh;
    background-color: #ebe992;
    color: black;
}

.exqoutesect{
    width: 60%;
    margin-left: 20%;
    padding-top: 5vh;
    padding-bottom: 5vh;
}

.exqoutesect p{
    text-align: center;
    font-size: 17px;
    margin-bottom: 2vh;
}

.ExperienceSect{
    width: 100%;
    overflow: hidden;
}

.wildlife{
    width: 100%;
}

.wildlifeimg{
    width: 50%;
    height: 100vh;
    float: left;
}

.wildlifeimg img{
    width: 100%;
    height: 100vh;
    
}

.wildlifecontent{
    width: 50%;
    min-height: 100vh;
    float: right;
}

.wildlifecontent h2{
    width: 60%;
    margin-left: 20%;
    margin-top: 5vh;

}

.wildlifecontent p{
    width: 60%;
    margin-left: 20%;
    margin-top: 2vh;
    font-size: 17px;

}

.wildernesswalks{
    width: 100%;
}

.wildernesswalksimg{
    width: 50%;
    height: 100vh;
    float: right;
}

.wildernesswalksimg img{
    width: 100%;
    height: 100vh;
}

.wildernesscontent{
    width: 50%;
    min-height: 100vh;
    float: left;
}

.wildernesscontent h2{
    width: 60%;
    margin-left: 20%;
    margin-top: 5vh;

}

.wildernesscontent p{
    width: 60%;
    margin-left: 20%;
    margin-top: 2vh;
    font-size: 17px;

}

.curdbike{
    width: 100%;
}

.curdbikeimg{
    width: 50%;
    height: 100vh;
    float: left;
}

.curdbikeimg img{
    width: 100%;
    height: 100vh;
    
}

.curdbikecontent{
    width: 50%;
    min-height: 100vh;
    float: right;
}

.curdbikecontent h2{
    width: 60%;
    margin-left: 20%;
    margin-top: 5vh;

}

.curdbikecontent p{
    width: 60%;
    margin-left: 20%;
    margin-top: 2vh;
    font-size: 17px;

}

@media (max-width: 880px) {
    
    .wildlifeimg{
        width: 100%;
        max-height: 70vh;
        float: none;
    }
    
    .wildlifeimg img{
        width: 60%;
        margin-left: 20%;
        height: 70vh;
        
    }
    
    
    .wildlifecontent{
        width: 100%;
        min-height: auto;
        float: none;
        overflow: hidden;
        padding-bottom: 5vh;
    }
    
    .wildlifecontent h2{
        width: 60%;
        margin-left: 20%;
        margin-top: 5vh;
    
    }
    
    .wildlifecontent p{
        width: 60%;
        margin-left: 20%;
        margin-top: 2vh;
        font-size: 17px;
    
    }




    .wildernesswalksimg{
        width: 100%;
        max-height: 70vh;
        float: none;
        overflow: hidden;
    }
    
    .wildernesswalksimg img{
        width: 60%;
        margin-left: 20%;
        height: 100vh;
    }
    
    .wildernesscontent{
        width: 100%;
        min-height: auto;
        float: none;
        overflow: hidden;
        padding-bottom: 5vh;
    }
    
    .wildernesscontent h2{
        width: 60%;
        margin-left: 20%;
        margin-top: 5vh;
    
    }
    
    .wildernesscontent p{
        width: 60%;
        margin-left: 20%;
        margin-top: 2vh;
        font-size: 17px;
    
    }



    .curdbikeimg{
        width: 100%;
        max-height: 70vh;
        float: none;
    }
    
    .curdbikeimg img{
        width: 60%;
        margin-left: 20%;
        height: 70vh;
        
    }
    
    .curdbikecontent{
        width: 100%;
        min-height: auto;
        float: none;
        overflow: hidden;
        padding-bottom: 5vh;
    }
    
    .curdbikecontent h2{
        width: 60%;
        margin-left: 20%;
        margin-top: 5vh;
    
    }
    
    .curdbikecontent p{
        width: 60%;
        margin-left: 20%;
        margin-top: 2vh;
        font-size: 17px;
    
    }

   
}


@media (max-width: 500px) {
    
    .wildlifeimg{
        width: 100%;
        max-height: 50vh;
        float: none;
    }
    
    .wildlifeimg img{
        width: 80%;
        margin-left: 10%;
        height: 50vh;
        
    }
    
    
    .wildlifecontent{
        width: 100%;
        height: auto;
        float: none;
        overflow: hidden;
        padding-bottom: 5vh;
    }
    
    .wildlifecontent h2{
        width: 80%;
        margin-left: 10%;
        margin-top: 5vh;
    
    }
    
    .wildlifecontent p{
        width: 80%;
        margin-left: 10%;
        margin-top: 2vh;
        font-size: 17px;
    
    }




    .wildernesswalksimg{
        width: 100%;
        max-height: 50vh;
        float: none;
        overflow: hidden;
    }
    
    .wildernesswalksimg img{
        width: 80%;
        margin-left: 10%;
        height: 50vh;
    }
    
    .wildernesscontent{
        width: 100%;
        min-height: auto;
        float: none;
        overflow: hidden;
        padding-bottom: 5vh;
    }
    
    .wildernesscontent h2{
        width: 80%;
        margin-left: 10%;
        margin-top: 5vh;
    
    }
    
    .wildernesscontent p{
        width: 80%;
        margin-left: 10%;
        margin-top: 2vh;
        font-size: 17px;
    
    }



    .curdbikeimg{
        width: 100%;
        max-height: 50vh;
        float: none;
    }
    
    .curdbikeimg img{
        width: 80%;
        margin-left: 10%;
        height: 50vh;
        
    }
    
    .curdbikecontent{
        width: 100%;
        min-height: auto;
        float: none;
        overflow: hidden;
        padding-bottom: 5vh;
    }
    
    .curdbikecontent h2{
        width: 80%;
        margin-left: 10%;
        margin-top: 5vh;
    
    }
    
    .curdbikecontent p{
        width: 80%;
        margin-left: 10%;
        margin-top: 2vh;
        font-size: 17px;
    
    }

   
}
