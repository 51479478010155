
.abtusqoute{
    width: 100%;
    min-height: 20vh;
    background-color: #ebe992;
    color: black;
    overflow: hidden;
}

.qoutesect{
    width: 60%;
    margin-left: 20%;
    padding-top: 5vh;
    padding-bottom: 5vh;

}

.qoutesect p{
    text-align: center;
    font-size: 17px;
}

.abtuspgabtussect{
    width: 100%;
    min-height: 50vh;
    overflow: hidden;
    padding-bottom: 5vh;
}

.abtuspgimg{
    width: 50%;
    float: left;
    margin-right: 5%;
}

.abtuspgimg img{
    width: 90%;
    margin-left: 10%;
    margin-top: 5vh;
}

.abtuspgpara{
    width: 45%;
    float: left;
}

.abtuspgpara h3{
    margin-top: 5vh;
}
.abtuspgpara p{
    width: 80%;
    margin-top: 2vh;
}


@media (max-width: 750px) {
    
    .abtuspgimg{
        width: 60%;
        margin-left: 20%;
        float: none;
        margin-right: 5%;
    }

    .abtuspgimg img{
        width: 100%;
        margin-left: 0%;
        margin-top: 5vh;
    }

    .abtuspgpara{
        width: 60%;
        margin-left: 20%;
        float: none;
    }

    .abtuspgpara p{
        width: 100%;
        margin-top: 2vh;
    }
   
}

@media (max-width: 500px) {
    
    .abtuspgimg{
        width: 80%;
        margin-left: 10%;
        float: none;
        margin-right: 5%;
    }

    .abtuspgimg img{
        width: 100%;
        margin-left: 0%;
        margin-top: 5vh;
    }

    .abtuspgpara{
        width: 80%;
        margin-left: 10%;
        float: none;
    }

    .abtuspgpara p{
        width: 100%;
        margin-top: 2vh;
    }
   
}

